import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import '../css/AnnouncementPage.css'; // Stil dosyanızı buraya ekleyin
import { getAnnouncements } from '../utils/api.js'; // API bağlantısını içe aktarın

const AnnouncementsPage = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchNews = async () => {
        try {
            const announcementData = await getAnnouncements(); // Duyuru verilerini API'den çekiyoruz
            setAnnouncements(announcementData);
        } catch (error) {
            setError('Duyurular yüklenemedi.');
        } finally {
            setLoading(false); // Yüklenme durumu bitti
        }
    };

    useEffect(() => {
        fetchNews(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz
    }, []);

    // Tarih formatını iyileştirmek için bir fonksiyon
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR', options); // Türkçe formatta tarih döndür
    };

    if (loading) return <Spinner animation="border" />; // Yükleniyor durumunu göster

    if (error) return <p>{error}</p>; // Hata durumunu göster

    return (
        <Container className="announcements-page">
            <h1 className="text-center mb-4">Duyurular</h1>
            <Row>
                {announcements.map((announcement, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{announcement.title}</Card.Title>
                                <Card.Text>
                                    {announcement.detail}
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">{formatDate(announcement.date)}</small> {/* Tarih formatını düzelt */}
                                    {/* <a href={announcement.link} className="btn btn-primary float-right">Devamını Oku</a> */}
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default AnnouncementsPage;
