import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // CSS Transition Import
import { FaArrowLeft, FaArrowRight, FaCalendarAlt } from 'react-icons/fa'; // Importing icons
import '../css/AnnouncementsList.css';

const AnnouncementsList = ({ announcements }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Items to display per page

    // Calculate items to display on each page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentAnnouncements = announcements.slice(indexOfFirstItem, indexOfLastItem);

    // Pagination control
    const totalPages = Math.ceil(announcements.length / itemsPerPage);
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    return (
        <div className="announcements-list">
            <TransitionGroup>
                {currentAnnouncements.map((announcement, index) => (
                    <CSSTransition key={index} timeout={500} classNames="slide">
                        <Link to={announcement.link} className="announcement-link">
                            <div className="announcement-item">
                                <div className="announcement-content">
                                    <h4 className="announcement-title">{announcement.title}</h4>
                                    <p className="announcement-detail">{announcement.detail}</p>
                                    <p className="announcement-date">
                                        <FaCalendarAlt className="date-icon" /> {/* Calendar icon */}
                                        {new Date(announcement.date).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </CSSTransition>
                ))}
            </TransitionGroup>

            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <FaArrowLeft /> {/* Previous icon */}
                </button>
                <span>{`Sayfa ${currentPage} / ${totalPages}`}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <FaArrowRight /> {/* Next icon */}
                </button>
            </div>
        </div>
    );
};

export default AnnouncementsList;
