import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/BankAccount.css'; // Ensure you create this CSS file for custom styling
import data from '../assets/data/Media'

const BankAccount = () => {

    return (
        <Container className="bank-accounts-container">
            <h2 className="text-center mb-4">Banka Hesapları</h2>
            <Row>
                {data.bankAccounts.map((account, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card className="account-card">
                            <Card.Body>
                                <Card.Title>{account.bankName}</Card.Title>
                                <Card.Text>
                                    <strong>Hesap Numarası:</strong> {account.accountNumber}
                                    <br />
                                    <strong>IBAN:</strong> {account.iban}
                                    <br />
                                    <strong>Şube Adresi:</strong> {account.branchAddress}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default BankAccount;
