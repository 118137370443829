import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getStaticTextById, updateStaticText } from '../utils/api'; // API fonksiyonlarını içe aktar
import misyon from '../assets/misyon.png';
import vizyon from '../assets/vizyon.png';
import '../css/AboutEdit.css'; // Import the CSS file
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS

const AboutEdit = ({ active }) => {
  const [missionText, setMissionText] = useState('');
  const [visionText, setVisionText] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchStaticTexts = async () => {
    try {
      const mission = await getStaticTextById(2); // mission ID'si
      const vision = await getStaticTextById(3); // vision ID'si
      setMissionText(mission.content);
      setVisionText(vision.content);
    } catch (error) {
      console.error('Static metinler yüklenemedi:', error.message);
      toast.error(`Static metinler yüklenemedi: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (active) {
      fetchStaticTexts();
    }
  }, [active]);

  const handleUpdate = async () => {
    try {
      await updateStaticText(2, { content: missionText }); // mission güncelle
      await updateStaticText(3, { content: visionText }); // vision güncelle
      toast.success('Misyon ve vizyon başarıyla güncellendi!');
    } catch (error) {
      console.error('Güncelleme yapılamadı:', error.message);
      toast.error(`Güncelleme yapılamadı: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
      </div>
    );
  }

  return (
    <Container className="aboutEdit-container my-4">
      <Row>
        <Col>
          {/* Misyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={misyon}
              alt="Misyon"
              style={{
                width: '80%',
                maxWidth: '200px',
                minWidth: '150px',
                borderRadius: '8px',
              }}
            />
            <textarea
              value={missionText}
              onChange={(e) => setMissionText(e.target.value)}
              style={{ width: '100%', height: '100px', marginTop: '10px' }}
            />
          </div>

          {/* Vizyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={vizyon}
              alt="Vizyon"
              style={{
                width: '100%',
                maxWidth: '200px',
                minWidth: '150px',
                borderRadius: '8px',
              }}
            />
            <textarea
              value={visionText}
              onChange={(e) => setVisionText(e.target.value)}
              style={{ width: '100%', height: '100px', marginTop: '10px' }}
            />
          </div>

          {/* Update Button */}
          <Button onClick={handleUpdate} variant="primary" className="small-button">
            Güncelle
          </Button>
        </Col>
      </Row>

      {/* ToastContainer for displaying toasts */}
      <ToastContainer />
    </Container>
  );
};

export default AboutEdit;
