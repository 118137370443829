import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/Branches.css'; // CSS dosyasını unutmayın
import { getBranches } from '../utils/api'; // API fonksiyonunu import edin
import data from '../assets/data/Media';

const Branches = () => {
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const branchData = await getBranches(); // API'den şubeler çekiliyor
                setBranches(branchData);
            } catch (error) {
                setError('Şubeler yüklenemedi.');
            } finally {
                setLoading(false);
            }
        };
        fetchBranches();
    }, []);

    if (loading) return <div>Yükleniyor...</div>;

    // Show default branches when there is an error
    if (error) {
        return (
            <Container className="branches-container">
                <h2 className="text-center mb-4">Şubelerimiz</h2>
                <Row>
                    {data.branches.map((branch, index) => (
                        <Col md={4} key={index} className="mb-4">
                            <Card className="branch-card">
                                <Card.Body>
                                    <Card.Title>{branch.name}</Card.Title>
                                    <Card.Text>
                                        <strong>Adres:</strong> {branch.address}
                                        <br />
                                        <strong>Telefon:</strong> {branch.phone}
                                        <br />
                                        <strong>Email:</strong> <a href={`mailto:${branch.email}`}>{branch.email}</a>
                                        <br />
                                        <br />
                                        <strong>Yetkili Kişi:</strong> {branch.authorized_person}
                                        <br />
                                        <strong>Yetkili Telefon:</strong> {branch.authorized_phone}
                                        <br />
                                        <strong>Yetkili Email:</strong> <a href={`mailto:${branch.authorized_email}`}>{branch.authorized_email}</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }

    return (
        <Container className="branches-container">
            <h2 className="text-center mb-4">Şubelerimiz</h2>
            <Row>
                {branches.map((branch, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card className="branch-card">
                            <Card.Body>
                                <Card.Title>{branch.name}</Card.Title>
                                <Card.Text>
                                    <strong>Adres:</strong> {branch.address}
                                    <br />
                                    <strong>Telefon:</strong> {branch.phone}
                                    <br />
                                    <strong>Email:</strong> <a href={`mailto:${branch.email}`}>{branch.email}</a>
                                    <br />
                                    <br />
                                    <strong>Yetkili Kişi:</strong> {branch.authorized_person}
                                    <br />
                                    <strong>Yetkili Telefon:</strong> {branch.authorized_phone}
                                    <br />
                                    <strong>Yetkili Email:</strong> <a href={`mailto:${branch.authorized_email}`}>{branch.authorized_email}</a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Branches;
