import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../css/NewsCarousel.css'; // Ensure the unique CSS is imported

const NewsCarousel = ({ newsItems }) => {
    const navigate = useNavigate(); // useNavigate hook'unu kullanıyoruz

    const handleNavigate = (id) => {
        navigate(`/news/${id}`); // İlgili id'ye yönlendir
    };

    return (
        <Carousel className="news-image-carousel">
            {newsItems.map((item, index) => (
                <Carousel.Item key={index}>
                    {/* Link yerine onClick ile gezinme sağlıyoruz */}
                    <div className="news-carousel-content" onClick={() => handleNavigate(item.id)} style={{ cursor: 'pointer' }}>
                        <div className="news-carousel-image-container">
                            <img
                                className="news-gallery-image"
                                src={item.image}
                                alt={`Slide ${index}`}
                            />
                        </div>

                        <div className="news-carousel-details">
                            <h3 className="news-carousel-title">{item.title}</h3>
                            <p className="news-carousel-description">{item.detail}</p>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default NewsCarousel;
