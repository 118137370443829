// src/components/GalleryList.js

import React, { useEffect, useState } from 'react';
import { getAllGalleries, createGallery, updateGallery, deleteGallery } from '../utils/api'; // Gerekli dosya yollarını güncelle
import { toast, ToastContainer } from 'react-toastify';
import '../css/GalleryList.css'; // CSS dosyasını burada kullanabilirsin
import noImage from '../assets/noImage.png'; // noImage yerine path eklenmiş olmalı

const GalleryList = ({ active }) => {
    const [galleries, setGalleries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imagePreview, setImagePreview] = useState(null); // Önizleme URL'si için state
    const [newGallery, setNewGallery] = useState({
        title: '', detail: '', date: new Date().toISOString().split('T')[0], gallery_pic: noImage
    });
    const [editingGallery, setEditingGallery] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const fetchGalleries = async () => {
        setLoading(true);
        try {
            const data = await getAllGalleries();
            setGalleries(data);
        } catch (error) {
            toast.error(`Error loading galleries: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (active) {
            fetchGalleries();
        }
        if (!active) {
            setEditingGallery(null);
            setIsEditingEnabled(false);
            setIsEnabled(false);
        }
    }, [active]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewGallery({ ...newGallery, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewGallery({ ...newGallery, gallery_pic: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEditImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditingGallery({ ...editingGallery, gallery_pic: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingGallery({ ...editingGallery, [name]: value });
    };


    const handleAddGallery = async (e) => {
        e.preventDefault();
        if (!isFormValid(newGallery)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedGallery = await createGallery(newGallery);
            setGalleries([addedGallery, ...galleries]);
            setNewGallery({ title: '', detail: '', date: new Date().toISOString().split('T')[0], gallery_pic: noImage });
            setImagePreview(null);
            setIsEnabled(false)
            toast.success('Gallery added successfully!');
        } catch (error) {
            toast.error(`Error adding gallery: ${error.message}`);
        }
    };

    const handleEditGallery = (gallery) => {
        setEditingGallery({
            ...gallery,
            date: new Date(gallery.date).toISOString().substr(0, 10)
        });
        setIsEditingEnabled(true);
        setIsEnabled(false);
    };

    const handleUpdateGallery = async (e) => {
        e.preventDefault();
        if (!isFormValid(editingGallery)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            await updateGallery(editingGallery.id, editingGallery);
            setGalleries(galleries.map((gallery) => (gallery.id === editingGallery.id ? editingGallery : gallery)));
            setEditingGallery(null);
            setIsEditingEnabled(false);
            toast.success('Gallery başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Error updating gallery: ${error.message}`);
        }
    };

    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false,
                closeOnClick: false,
                draggable: false
            }
        );
    };

    const confirmDelete = async (id, closeToast) => {
        try {
            await deleteGallery(id);
            setGalleries(galleries.filter(gallery => gallery.id !== id));
            toast.success('Fotograf başarıyla silindi!');
        } catch (error) {
            toast.error(`Fotograf silinirken hata: ${error.message}`);
        }
        closeToast();
    };

    const handleCancelEdit = () => {
        setEditingGallery(null);
        setIsEditingEnabled(false);
    };

    const isFormValid = (gallery) => {
        const requiredFields = ['title', 'detail', 'date', 'gallery_pic'];
        for (let field of requiredFields) {
            if (!gallery[field]) {
                return false;
            }
        }
        return true;
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
            </div>
        );
    }

    return (
        <div className="gallery-list-container mb-5">
            <table className="gallery-list-table">
                <thead>
                    <tr>
                        <th>Başlık</th>
                        <th>İçerik</th>
                        <th>Görsel</th>
                        <th>Tarih</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="title"
                                placeholder="Başlık"
                                value={newGallery.title}
                                onChange={handleInputChange}
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="detail"
                                placeholder="İçerik"
                                value={newGallery.detail}
                                onChange={handleInputChange}
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                disabled={!isEnabled}
                            />
                            {newGallery.gallery_pic && <img src={imagePreview} alt="Önizleme" className="gallery-image" />}
                        </td>
                        <td>
                            <input
                                type="date"
                                name="date"
                                value={newGallery.date}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button onClick={handleAddGallery} className="btn-gallery" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button onClick={() => { setIsEnabled(!isEnabled); setIsEditingEnabled(false); setEditingGallery(null); }} className="btn-gallery">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {galleries.map((gallery) => (
                        <tr key={gallery.id}>
                            {editingGallery?.id === gallery.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingGallery.title}
                                            onChange={handleEditInputChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="detail"
                                            value={editingGallery.detail}
                                            onChange={handleEditInputChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleEditImageChange}
                                        />
                                        {editingGallery.gallery_pic && <img src={imagePreview} alt="Önizleme" className="gallery-image" />}
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingGallery.date}
                                            onChange={handleEditInputChange}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleUpdateGallery} className="btn-gallery">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-gallery">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{gallery.title}</td>
                                    <td>{gallery.detail}</td>
                                    <td>
                                        {gallery.gallery_pic ? <img src={gallery.gallery_pic} alt={gallery.title} className="gallery-image" /> : <img src={noImage} alt="No preview" className="gallery-image" />}
                                    </td>
                                    <td>{new Date(gallery.date).toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleEditGallery(gallery)} className="btn-gallery">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(gallery.id)} className="btn-gallery">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer />
        </div>
    );
};

export default GalleryList;
