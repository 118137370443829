import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getNewsById } from '../utils/api'; // API'den detay verisi çekmek için fonksiyon
import '../css/NewsDetailPage.css'; // CSS dosyasını import edin

const NewsDetailPage = () => {
    const { id } = useParams(); // URL'den haber id'sini al
    const [newsDetail, setNewsDetail] = React.useState(null); // Detay verilerini tutmak için state
    const [loading, setLoading] = React.useState(true); // Yükleniyor durumunu göstermek için state
    const [error, setError] = React.useState(null); // Hata durumunu göstermek için state

    const fetchNewsDetail = async () => {
        try {
            const detail = await getNewsById(id); // API'den haber detayını çek
            setNewsDetail(detail);
        } catch (error) {
            setError('Haber detayları yüklenemedi.');
        } finally {
            setLoading(false); // Yüklenme durumu bitti
        }
    };

    React.useEffect(() => {
        fetchNewsDetail(); // useEffect içinde detay verisi çekme fonksiyonunu çağırıyoruz
    }, [id]);

    // Tarih formatını iyileştirmek için bir fonksiyon
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR', options); // Türkçe formatta tarih döndür
    };

    if (loading) return <div>Yükleniyor...</div>; // Eğer yükleniyorsa gösterilecek kısım
    if (error) return <div>{error}</div>; // Eğer bir hata varsa gösterilecek kısım

    return (
        <Container className="news-detail-page">
            {newsDetail && (
                <Row className="mb-4">
                    <Col md={8} className="mx-auto">
                        <Card className="news-detail-card">
                            <Row>
                                <Row>
                                    <Card.Img 
                                        src={newsDetail.image} 
                                        alt={newsDetail.title} 
                                        className="rounded-image" // Resim için sınıf ekledik
                                    />
                                </Row>
                                <Row className="news-detail-content">
                                    <Card.Body>
                                        <Card.Title className="news-title">{newsDetail.title}</Card.Title>
                                        <Card.Text>
                                            {newsDetail.detail}
                                        </Card.Text>
                                        <Card.Footer>
                                            <small className="text-muted">{formatDate(newsDetail.date)}</small>
                                        </Card.Footer>
                                    </Card.Body>
                                </Row>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default NewsDetailPage;
