import React, { useEffect, useState } from 'react';
import { getNews, createNews, updateNews, deleteNews } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Toastify stilleri
import '../css/NewsList.css';
import noImage from '../assets/noImage.png'

const NewsList = ({ active }) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false); // Yüklenme durumunu ekledik
    const [imagePreview, setImagePreview] = useState(null); // Yüklenme durumunu ekledik
    const [newNews, setNewNews] = useState({
        title: '', detail: '', image: noImage, date: new Date().toISOString().split('T')[0]
    });
    const [editingNews, setEditingNews] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const fetchNews = async () => {
        setLoading(true); // Yüklenme başladığında true yap
        try {
            const data = await getNews();
            setNews(data);
        } catch (error) {
            toast.error(`Haberleri yüklerken hata: ${error.message}`);
        } finally {
            setLoading(false); // API'den veri geldikten sonra false yap
        }
    };

    useEffect(() => {
        if (active) {
            fetchNews();
        }
        if (!active) {
            setEditingNews(null);
            setIsEditingEnabled(false);
            setIsEnabled(false);
        }
    }, [active]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewNews({ ...newNews, [name]: value });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewNews({ ...newNews, image: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Önizleme URL'sini kaydet
            };
            reader.readAsDataURL(file);
        }
    };
    const handleEditImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditingNews({ ...editingNews, image: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Önizleme URL'sini kaydet
            };
            reader.readAsDataURL(file);
        }
    };
    const validateFields = (news) => {
        const requiredFields = ['title', 'detail', 'image', 'date'];
        for (let field of requiredFields) {
            if (!news[field]) {
                return false;
            }
        }
        return true;
    };

    const handleAddNews = async (e) => {
        e.preventDefault();
        if (!validateFields(newNews)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedNews = await createNews(newNews);
            setNews([addedNews, ...news]);
            setNewNews({ title: '', detail: '', image: noImage, date: new Date().toISOString().split('T')[0] });
            setImagePreview(null);
            setIsEnabled(false)
            toast.success('Haber başarıyla eklendi!');
        } catch (error) {
            toast.error(`Haber eklenirken hata: ${error.message}`);
        }
    };

    const handleEditNews = (newsItem) => {
        setEditingNews({
            ...newsItem,
            date: new Date(newsItem.date).toISOString().substr(0, 10)
        });
        setIsEditingEnabled(true);
        setIsEnabled(false)
    };

    const handleUpdateNews = async (e) => {
        e.preventDefault();

        if (!validateFields(editingNews)) { // Eğer form geçersizse API çağrısı yapılmaz
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            await updateNews(editingNews.id, editingNews);
            setNews(news.map((item) => (item.id === editingNews.id ? editingNews : item)));
            setEditingNews(null);
            setIsEditingEnabled(false);
            toast.success('Haber başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Haber güncellenirken hata: ${error.message}`);
        }
    };

    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = async (id, closeToast) => {
        // Silme işlemi burada gerçekleştirilecek
        try {
            await deleteNews(id);
            setNews(news.filter(item => item.id !== id));
            toast.success('Kullanıcı başarıyla silindi!');
        } catch (error) {
            toast.error(`Kullanıcı silinirken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingNews({ ...editingNews, [name]: value });
    };

    const handleCancelEdit = () => {
        setEditingNews(null);
        setIsEditingEnabled(false);
    };
    if (loading) {
        return (
            <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
            </div>
        );
    }
    return (
        <div className="news-list-container mb-5">
            <table className="news-list-table">
                <thead>
                    <tr>
                        <th>Başlık</th>
                        <th>İçerik</th>
                        <th>Görsel</th>
                        <th>Tarih</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="title"
                                placeholder="Başlık"
                                value={newNews.title}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <textarea
                                name="detail"
                                placeholder="İçerik"
                                value={newNews.detail}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="file"
                                accept="image/*"
                                name="image"
                                onChange={handleImageChange}
                                disabled={!isEnabled}
                            />
                            {imagePreview && <img src={imagePreview} alt="Önizleme" className="news-image" />}
                        </td>
                        <td>
                            <input
                                type="date"
                                name="date"
                                value={newNews.date}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddNews} className="btn-news" title="Haber Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => {
                                setEditingNews(null);
                                setIsEditingEnabled(false); setIsEnabled(!isEnabled)
                            }} className="btn-news" title="Ekleme Modunu Aç/Kapa">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>

                    {news.map((item) => (
                        <tr key={item.id}>
                            {editingNews?.id === item.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingNews.title}
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            name="detail"
                                            value={editingNews.detail}
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"

                                            onChange={handleEditImageChange}
                                        />
                                        {editingNews.image && <img src={imagePreview} alt="Önizleme" className="news-image" />}
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingNews.date}
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleUpdateNews} className="btn-news" title="Güncelle">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-news" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{item.title}</td>
                                    <td>{item.detail}</td>
                                    <td>
                                        {item.image ? <img src={item.image} alt={item.title} className="news-image" /> : <img src={noImage} alt="No preview" className="news-image" />}
                                    </td>
                                    <td>
                                        {item.date ? new Date(item.date).toLocaleDateString() : new Date().toLocaleDateString()}
                                    </td>

                                    <td>
                                        <button onClick={() => handleEditNews(item)} className="btn-news" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(item.id)} className="btn-news" title="Sil">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer />
        </div>
    );
};

export default NewsList;
