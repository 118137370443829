import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const ProtectedRoute = ({ children, requiredRole }) => {
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            setLoading(false);
            return;
        }
        try {
            const decodedToken = jwt_decode(token);
            // Kullanıcı rolünü kontrol et
            if (requiredRole && decodedToken.role !== requiredRole) {
                // Eğer kullanıcı admin ise, true olacak ve tüm sayfalara erişebilecek
                if (decodedToken.role === 'admin') {
                    setIsAuthorized(true);  // Admin her zaman yetkili
                } else {
                    setIsAuthorized(false); // Admin değilse ve role uygun değilse yetkisiz
                }
            } else {
                setIsAuthorized(true); // requiredRole yoksa veya role uygunsa yetkili
            }
        } catch (error) {
            console.error('Token doğrulama hatası:', error);
            setIsAuthorized(false);
        } finally {
            setLoading(false);
        }
    }, [token, requiredRole]);

    // Yükleniyor göstergesi
    if (loading) {
        return <div>Loading...</div>; // Yükleniyor mesajı
    }

    // Yetkisiz erişim durumunda yönlendirme
    if (!isAuthorized) {
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export default ProtectedRoute;
