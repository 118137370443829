import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../css/MessageOfPresident.css'; // Ensure you create this CSS file for custom styling
import baskanImg from '../assets/galery/resim7.jpg';
import baskanMessage from '../assets/message.png';
import { getStaticTextById } from '../utils/api'; // Statik metinleri çekmek için API fonksiyonu

const MessageOfPresident = () => {
    const [messageTitle, setMessageTitle] = useState('Türkiye Şehit Aileleri ve Gaziler Vakfı olarak amacımız;');
    const [messageText, setMessageText] = useState(`Atatürk ilkeleri ışığında nitelikli bireylerin yetiştirilmesi,
    şehit ve gazi ailelerinin eğitim, sağlık ve istihdam gibi temel ihtiyaçlarını göz önünde bulundurarak,
    mesleki uzmanlık, tarafsızlık, şeffaflık, gizlilik, güvenirlilik esaslarını benimseyerek,
    tüm bunlar için en yüksek çabayı göstermektir.`);
    const [messageText2, setMessageText2] = useState(`Ülkemiz uğruna canını feda eden şehitlerimizin geride bıraktıkları
    eşleri, çocukları, anaları, babaları için ve yine ülkemiz uğruna bedenini siper etmiş gazilerimiz için
    yurdumuzun tüm insanı ile birlikte elimizden gelen her şeyi yapma zamanıdır...`);
    const [presidentName, setPresidentName] = useState('Gazi Üstüner MİDİLİÇ');
    const [presidentTitle, setPresidentTitle] = useState('TUSAG Genel Başkanı');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStaticTexts = async () => {
            try {
                // API'den statik metinleri çekme
                const president_message = await getStaticTextById(4);  // Metin için ID 11
                const president_name = await getStaticTextById(9);  // Başkanın ismi için ID 13
                const president_title = await getStaticTextById(8); // Başkanın unvanı için ID 14

                // Gelen verileri state'lere aktar
                setMessageText(president_message.content || messageText);
                setPresidentName(president_name.content || presidentName);
                setPresidentTitle(president_title.content || presidentTitle);

                setError(null)
            } catch (error) {
                // Hata durumunda, varsayılan değerleri kullan ve hata mesajı göster
                console.error('Mesaj metinleri çekilemedi:', error.message);
            }
        };

        fetchStaticTexts();
    }, []);

    return (
        <Container className="president-message-container">
            {/* Resmi ekleyin */}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img
                    src={baskanMessage}
                    alt="Türkiye Şehit Aileleri ve Gaziler Vakfı"
                    style={{
                        width: '80%',
                        maxWidth: '250px',
                        minWidth: '150px',
                        borderRadius: '8px'
                    }}
                />
            </div>

            <Row>
                <Col md={4} className="mb-4">
                    <Image
                        src={baskanImg}
                        alt="Başkan"
                        fluid
                        className="president-image"
                    />
                </Col>
                <Col md={8}>
                    <div className="content">
                        {error && <p>{error}</p>}
                        <p className="title">{messageTitle}</p>
                        <p>{messageText}</p>
                        <p>{messageText2}</p>
                    </div>
                    <p>
                        Saygılarımla,<br />
                        {presidentName}<br />
                        {presidentTitle}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default MessageOfPresident;
