import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { sendContactMessage } from '../utils/api'; // Ensure the API path is correct
import '../css/Contact.css'; // Ensure this CSS file exists for custom styling

const Contact = () => {
    const [name, setName] = useState(''); // State for Name
    const [email, setEmail] = useState(''); // State for Email
    const [message, setMessage] = useState(''); // State for Message
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const [errorMessage, setErrorMessage] = useState(''); // State for error message

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        try {
            // Send contact message API call
            const response = await sendContactMessage(name, email, message);
            
            // If response contains a success message
            setSuccessMessage(response.message); // Assuming the response contains a 'message' key
            setErrorMessage(''); // Clear any previous error
            // Reset form fields
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            // Handle error case, assuming error contains a message
            setErrorMessage(error.response?.data?.message || 'Mesaj gönderilirken bir hata oluştu.'); 
            setSuccessMessage(''); // Clear any previous success message
        }
    };

    return (
        <Container className="contact-container my-4">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="text-center mb-4">Bize Ulaşın</h1>
                    <p className="text-center mb-4">
                        Sorularınızı veya yorumlarınızı bize iletmekten çekinmeyin.
                        Aşağıdaki formu doldurarak bizimle iletişime geçebilirsiniz.
                    </p>

                    {/* Success Message */}
                    {successMessage && (
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>
                    )}

                    {/* Error Message */}
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}

                    {/* Contact Form */}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>İsim</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="İsminizi girin"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email adresinizi girin"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label>Mesaj</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Mesajınızı yazın"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3 w-100">
                            Mesaj Gönder
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;
