import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/NewsPage.css'; // CSS dosyasını import edin
import { getNews } from '../utils/api'; // API fonksiyonlarını import edin

const NewsPage = () => {
    const [news, setNews] = useState([]); // Haberleri tutmak için state
    const [loading, setLoading] = useState(true); // Yükleniyor durumunu göstermek için state
    const [error, setError] = useState(null); // Hata durumunu göstermek için state

    const fetchNews = async () => {
        try {
            const newsData = await getNews(); // Haber verilerini API'den çekiyoruz
            setNews(newsData);
        } catch (error) {
            setError('Haberler yüklenemedi.');
        } finally {
            setLoading(false); // Yüklenme durumu bitti
        }
    };
    useEffect(() => {
        fetchNews(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz
    }, []);

    if (loading) return <div>Yükleniyor...</div>; // Eğer yükleniyorsa gösterilecek kısım
    if (error) return <div>{error}</div>; // Eğer bir hata varsa gösterilecek kısım

    return (
        <Container className="news-page">
            <h1 className="text-center mb-4">Haberler</h1>
            <Row>
                {news.map((item, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card>
                            <Card.Img src={item.image} alt={item.title} />
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text>
                                    {item.text}
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">{item.date}</small>
                                    <a href={`#/news/${item.id}`} className="btn btn-primary float-right">Devamını Oku</a>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default NewsPage;
