import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Import toast
import '../css/MessageOfPresidentEdit.css'; 
import baskanImg from '../assets/galery/resim7.jpg';
import baskanMessage from '../assets/message.png';
import { getStaticTextById, updateStaticText } from '../utils/api'; 

const MessageOfPresidentEdit = ({ active }) => {
    const [messageContent, setMessageContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchMessageContent = async () => {
        try {
            const response = await getStaticTextById(4);
            setMessageContent(response.content);
        } catch (error) {
            console.error('Başkan mesajı yüklenirken hata oluştu', error);
            toast.error('Mesaj yüklenirken bir hata oluştu.'); // Error toast
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (active) {
            fetchMessageContent();
        }
    }, [active]);

    const handleInputChange = (e) => {
        setMessageContent(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await updateStaticText(4, { content: messageContent });
            toast.success('Mesaj başarıyla güncellendi!'); // Success toast
        } catch (error) {
            console.error('Mesaj güncellenirken hata oluştu', error);
            toast.error('Mesajı güncellerken bir hata oluştu.'); // Error toast
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
            </div>
        );
    }

    return (
        <Container className="president-message-container mt-4">
            <div className="text-center mb-4">
                <img
                    src={baskanMessage}
                    alt="Türkiye Şehit Aileleri ve Gaziler Vakfı"
                    style={{
                        width: '80%',
                        maxWidth: '250px',
                        minWidth: '150px',
                        borderRadius: '8px'
                    }}
                />
            </div>

            <Row>
                <Col md={4} className="mb-4">
                    <Image
                        src={baskanImg}
                        alt="Başkan"
                        fluid
                        className="president-image"
                    />
                </Col>

                <Col md={8}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formParagraph">
                            <Form.Label>Mesaj İçeriği</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                name="content"
                                value={messageContent}
                                onChange={handleInputChange}
                                required
                                placeholder="Başkanın mesajını buraya yazın..."
                                style={{ resize: 'vertical', height: '300px', width: '100%' }}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-4">
                            <Button
                                variant="primary"
                                type="submit"
                                className="small-button"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Güncelleniyor...' : 'Mesajı Güncelle'}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default MessageOfPresidentEdit;
