import React, { useEffect, useState } from 'react';
import { getAllUsers, createUser, updateUser, deleteUser, resetPassword } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';  // Import Toastify
import '../css/UserList.css'; // Import the CSS file

const UserList = ({ active }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        role_id: 2,
        created_at: new Date().toISOString().split('T')[0],
        is_active: 1,
        is_del: 0 // Kullanıcı silinmiş mi (varsayılan olarak false)
    });
    const [editingUser, setEditingUser] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false); // Track if editing is enabled
    const [isEnabled, setIsEnabled] = useState(false); // Track if editing is enabled
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);


    const roleMap = {
        1: 'Admin',
        2: 'User',
        3: 'Moderator'
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await getAllUsers();
            setUsers(data);
        } catch (error) {
            toast.error(`Kullanıcıları yüklerken hata: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        if (active) {
            fetchUsers();
        }
        if (!active) {
            setEditingUser(null);
            setIsEditingEnabled(false);
            setIsEnabled(false);
        }
    }, [active]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setNewUser((prevState) => ({
            ...prevState,
            [name]:
                // Eğer 'is_del' gibi bir alan ise sayıya çeviriyoruz
                name === 'is_del' || name === 'is_active'
                    ? parseInt(value, 10)   // Gelen değeri 0 veya 1'e dönüştürüyoruz (tam sayı)
                    : value                 // Diğer alanlar için gelen değeri olduğu gibi alıyoruz
        }));
    };
    const isFormValid = (user) => {
        const requiredFields = ['username', 'email', 'role_id'];
        for (let field of requiredFields) {
            if (!user[field]) {
                toast.error('Tüm alanların doldurulması gerekmektedir!');
                return false;
            }
        }
        return true;
    };
    const handleAddUser = async (e) => {
        e.preventDefault();
        if (!isFormValid(newUser)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedUser = await createUser(newUser);
            setUsers([addedUser, ...users]);
            setNewUser({ username: '', email: '', password: '', role_id: 2, date: new Date().toISOString().split('T')[0], is_active: true, is_del: false });
            setIsEnabled(!isEnabled);
            toast.success('Kullanıcı başarıyla eklendi!');
        } catch (error) {
            toast.error(`Kullanıcı eklenirken hata: ${error.message}`);
        }
    };
    const handleEditUser = (user) => {
        setEditingUser({ ...user });// Datepicker için format);
        setIsEditingEnabled(true);
        setIsEnabled(false);
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        if (!isFormValid(editingUser)) { // Eğer form geçersizse API çağrısı yapılmaz
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }

        try {
            await updateUser(editingUser.id, editingUser);
            setUsers(users.map((user) => (user.id === editingUser.id ? editingUser : user)));
            setEditingUser(null);
            setIsEditingEnabled(false);
            toast.success('Kullanıcı başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Kullanıcı güncellenirken hata: ${error.message}`);
        }
    };
    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = async (id, closeToast) => {
        // Kullanıcının is_del alanını 1 olarak güncelle
        try {
            // Find the user by id and update is_del to 1
            const updatedUsers = users.map(user =>
                user.id === id ? { ...user, is_del: 1 } : user
            );
            await deleteUser(id);
            // Set the updated users list to the state
            setUsers(updatedUsers);
            setEditingUser(null);
            setIsEditingEnabled(false);

            // Optionally, you can make an API call to update the user in the backend
            // await updateUser(id, { is_del: 1 });

            toast.success('Kullanıcı başarıyla silindi!');
        } catch (error) {
            toast.error(`Kullanıcı silinirken hata: ${error.message}`);
        }

        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };
    const handleCancelEdit = () => {
        setEditingUser(null); // Düzenleme modunu kapatır
        setIsEditingEnabled(false); // Düzenlemeyi devre dışı bırakır
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        setEditingUser((prevState) => ({
            ...prevState,
            [name]:
                // Eğer 'is_del' gibi bir alan ise sayıya çeviriyoruz
                name === 'is_del' || name === 'is_active'
                    ? parseInt(value, 10)   // Gelen değeri 0 veya 1'e dönüştürüyoruz (tam sayı)
                    : value                 // Diğer alanlar için gelen değeri olduğu gibi alıyoruz
        }));
    };

    const confirmReset = async (id, closeToast) => {
        try {
            await resetPassword(id);
            setEditingUser(null);
            setIsEditingEnabled(false);
            toast.success('Şifre başarıyla sıfırlandı!');
        } catch (error) {
            toast.error(`Şifre sıfırlanırken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.

    };

    const handleResetPassword = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten sıfırlamak istiyor musunuz?</p>
                    <button onClick={() => confirmReset(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    if (loading) {
        return (
            <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
            </div>
        );
    }

    return (
        <div className="user-list-container mb-5">
            <table className="user-list-table">
                <thead>
                    <tr>
                        <th>Kullanıcı Adı</th>
                        <th>Email</th>
                        <th>Şifre</th>
                        <th>Rol</th>
                        <th>Durum</th>
                        <th>Silme</th>
                        <th>Kayıt Tarihi</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="username"
                                placeholder="Kullanıcı Adı"
                                value={newUser.username}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={newUser.email}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Şifre"
                                    value={newUser.password}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEnabled}
                                    style={{ paddingRight: '30px' }}
                                />
                                <i
                                    onClick={newUser.password ? () => setIsPasswordVisible(!isPasswordVisible) : null} // Check if password is not empty
                                    className={`fas fa-eye${isPasswordVisible ? '-slash' : ''}`}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: newUser.password || isEnabled ? 'pointer' : 'not-allowed' // Change cursor based on password presence
                                    }}
                                    title={isPasswordVisible ? 'Şifreyi Gizle' : 'Şifreyi Göster'}
                                ></i>
                            </div>
                        </td>
                        <td>
                            <select
                                name="role_id"
                                value={newUser.role_id}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={1}>Admin</option>
                                <option value={2}>User</option>
                                <option value={3}>Moderator</option>
                            </select>
                        </td>
                        <td>
                            <select
                                name="is_active"
                                value={newUser.is_active}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={1}>Aktif</option>
                                <option value={0}>Pasif</option>
                            </select>
                        </td>
                        <td>
                            <select
                                name="is_del"
                                value={newUser.is_del}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={1}>Silinmiş</option>
                                <option value={0}>Aktif</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="date"
                                name="created_at"
                                value={newUser.created_at}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddUser} className="btn-user" title="Kullanıcı Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => { setNewUser(newUser); setEditingUser(null); setIsEditingEnabled(false); setIsEnabled(!isEnabled) }} className="btn-user" title="Ekleme Modunu Aç/Kapa">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {users?.map((user) => (
                        <tr key={user.id}>
                            {editingUser?.id === user.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="username"
                                            value={editingUser.username}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="email"
                                            value={editingUser.email}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleResetPassword(user.id)} className="btn btn-secondary" title="Şifreyi Sıfırla">
                                            <i className="fas fa-key"></i> Sıfırla
                                        </button>
                                    </td>
                                    <td>
                                        <select
                                            name="role_id"
                                            value={editingUser.role_id}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={1}>Admin</option>
                                            <option value={2}>User</option>
                                            <option value={3}>Moderator</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            name="is_active"
                                            value={editingUser.is_active}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={0}>Pasif</option>
                                            <option value={1}>Aktif</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            name="is_del"
                                            value={editingUser.is_del}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={0}>Aktif</option>
                                            <option value={1}>Silinmiş</option>

                                        </select>
                                    </td>
                                    <td>{new Date(user.created_at).toLocaleDateString()}</td>

                                    <td>
                                        <button onClick={handleUpdateUser} className="btn-user" title="Güncelle">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-user" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <button className="btn btn-secondary" title="Şifreyi Sıfırlamak için düzenlemeyi açın">
                                            <i className="fas fa-key"></i> Sıfırla
                                        </button>
                                    </td>
                                    <td>{roleMap[user.role_id]}</td>
                                    <td>{user.is_active ? 'Aktif' : 'Pasif'}</td>
                                    <td>{user.is_del ? 'Silinmiş' : 'Aktif'}</td>
                                    <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleEditUser(user)} className="btn-user" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(user.id)} className="btn-user" title="Sil">
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer position='bottom-right' />
        </div>
    );
};

export default UserList;
