import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../css/ContactEdit.css'; // CSS dosyasını ekle
import { getStaticTextById, updateStaticText } from '../utils/api'; // API fonksiyonlarını içe aktar
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify

const ContactEdit = ({ active }) => {
    const [contactInfo, setContactInfo] = useState({
        contact_address: '',
        contact_phone: '',
        contact_email: ''
    });
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fetchStaticTexts = async () => {
        try {
            const contactInfo_address = await getStaticTextById(5);
            const contactInfo_phone = await getStaticTextById(6);
            const contactInfo_email = await getStaticTextById(7);

            setContactInfo({
                contact_address: contactInfo_address.content,
                contact_phone: contactInfo_phone.content,
                contact_email: contactInfo_email.content
            });
        } catch (error) {
            console.error('Error fetching contact info', error);
            toast.error('İletişim bilgileri yüklenirken bir hata oluştu.');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (active) {
            fetchStaticTexts();
        }
    }, [active]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactInfo({
            ...contactInfo,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await updateStaticText(5, { content: contactInfo.contact_address });
            await updateStaticText(6, { content: contactInfo.contact_phone });
            await updateStaticText(7, { content: contactInfo.contact_email });

            toast.success('İletişim bilgileri başarıyla güncellendi!');
        } catch (error) {
            console.error('Error updating contact info', error);
            toast.error('İletişim bilgilerini güncellerken bir hata oluştu.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return <div className="loading-message">Yükleniyor...</div>; // Yükleniyor durumu
    }

    return (
        <Container className="contact-container my-4">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="text-center mb-4">İletişim Bilgileri Düzenleme</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Adres</Form.Label>
                            <Form.Control
                                type="text"
                                name="contact_address"
                                value={contactInfo.contact_address}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhone">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control
                                type="text"
                                name="contact_phone"
                                value={contactInfo.contact_phone}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="contact_email"
                                value={contactInfo.contact_email}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-4"> {/* Flex container for button positioning */}
                            <Button variant="primary" type="submit" className="small-button" disabled={isSubmitting}>
                                {isSubmitting ? 'Güncelleniyor...' : 'Güncelle'}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>

            {/* ToastContainer for displaying toast notifications */}
            <ToastContainer />
        </Container>
    );
};

export default ContactEdit;
