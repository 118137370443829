import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import data from '../assets/data/Media'; // Haklar verisini JSON dosyasından alıyoruz
import { Container } from 'react-bootstrap';


const RightsDetails = () => {
    const { id } = useParams(); // URL'den dinamik id'yi alıyoruz
    const right = data.rightsData.find((r) => r.id === parseInt(id)); // JSON'daki uygun hakkı buluyoruz

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfa yüklendiğinde en üste git
    }, []);

    if (!right) {
        return (
            <Container className="mt-4">
                <p>Hata: Detay bulunamadı.</p>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <h2>{right.title}</h2>
            <p>{right.details}</p> {/* Uzun detay yazısını buraya ekliyoruz */}
        </Container>
    );
};

export default RightsDetails;
