import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import '../css/Footer.css'; // Stiller için dosya
import logo from '../logo.svg';
import { getStaticTextById } from '../utils/api'; // API fonksiyonunu import edin

const Footer = () => {
  // State'ler
  const [address, setAddress] = useState('Kızılırmak Mah. 1443. cad. No:33/16 Çankaya/Ankara , Ankara, Turkey');
  const [phone, setPhone] = useState('(0312) 433 83 80');
  const [email, setEmail] = useState('info@tusag.com.tr');

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        // Dinamik verileri çekme girişimi
        const addressData = await getStaticTextById(5); // Adres için ID 6
        const phoneData = await getStaticTextById(6);   // Telefon için ID 7
        const emailData = await getStaticTextById(7);   // Mail için ID 8

        // Gelen veriler varsa state'leri güncelle
        setAddress(addressData.content || address);
        setPhone(phoneData.content || phone);
        setEmail(emailData.content || email);
      } catch (error) {
        // Eğer hata alırsak, varsayılan değerlerle devam ediyoruz
        console.error('İletişim bilgileri çekilemedi:', error.message);
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <footer className="footer bg-light py-4">
      <Container>
        <Row>
          {/* TUSAG Amblemi, Misyon - Vizyon */}
          <Col md={4}>
            <img
              src={logo} // TUSAG logosunun URL'si
              alt="TUSAG Logo"
              style={{ width: '300px' }}
            />
            <p>
              Vakfımız, Şehit Aileleri ve Gazilerimize Eğitim, Sağlık ve İstihdam Olanakları Sağlamak İçin kurulmuştur.
            </p>
          </Col>

          {/* TUSAG Bölümü ve Alt Menüler */}
          <Col md={2}>
            <h5>TUSAG</h5>
            <ul className="list-unstyled">
              <li><Link to="/about">Hakkımızda</Link></li>
              <li><Link to="/message">Başkanın Mesajı</Link></li>
              <li><Link to="/branches">Şubelerimiz</Link></li>
              <li><Link to="/news">Haberler</Link></li>
              <li><Link to="/announcement">Duyurular</Link></li>
            </ul>
          </Col>

          {/* Bağışlar Bölümü ve Bağış Türleri */}
          <Col md={2}>
            <h5>Bağışlar</h5>
            <ul className="list-unstyled">
              <li><Link to="/donate">Bağışlar</Link></li>
              <li><Link to="/bankAccount">Banka Hesaplarımız</Link></li>
            </ul>
          </Col>

          {/* İletişim ve Sosyal Medya */}
          <Col md={3}>
            <h5>İletişim</h5>
            <p>
              <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">
                <FaMapMarkerAlt />
              </a> {address} {/* Dinamik veya varsayılan adres */}
            </p>
            <p>
              <a href={`tel:${phone}`}><FaPhone /></a> {phone} {/* Dinamik veya varsayılan telefon */}
            </p>
            <p>
              <a href={`mailto:${email}`}><FaEnvelope /></a> {email} {/* Dinamik veya varsayılan e-posta */}
            </p>
          </Col>

          {/* Sosyal Medya */}
          <Col md={1}>
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} className="d-block mb-2" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={30} className="d-block mb-2" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} className="d-block mb-2" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={30} className="d-block mb-2" />
              </a>
            </div>
          </Col>
        </Row>

        {/* Copyright */}
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">© 2024 MDSiber. Tüm Hakları Saklıdır.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
