import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../css/ImageGallery.css'; // Ensure the unique CSS is imported
import ImageCarouselGallery from './ImageCarouselGallery';

const UniqueImageGallery = ({ galleries, openModal }) => {
    return (
        <Carousel className="unique-image-carousel">
            {galleries.map((gallery, index) => (
                <Carousel.Item key={index}>
                    <div className="unique-carousel-content">
                        <div className="unique-carousel-image-container">
                            <img
                                className="unique-gallery-image"
                                src={gallery.gallery_pic ? gallery.gallery_pic : gallery}
                                alt={`Slide ${index + 1}`}
                                onClick={() => openModal(<ImageCarouselGallery images={galleries} />)}
                            />
                        </div>
                        <div className="unique-carousel-details">
                            <h2 className="unique-carousel-title">{gallery.title}</h2>
                            <p className="unique-carousel-description">{gallery.detail}</p>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default UniqueImageGallery;
