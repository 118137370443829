import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageCarousel from '../utils/ImageCarousel';
import NewsCarousel from '../utils/NewsCarousel';
import AnnouncementsList from '../utils/AnnouncementsList';
import ImageGallery from '../utils/ImageGallery';
import VideoGallery from '../utils/VideoGallery';
import Modal from '../utils/Modal'; // Modal bileşenini import edin
import '../css/Home.css'; // Stil dosyasını ayrı bir dosyaya ekleyebiliriz
import data from '../assets/data/Media'; // İç içe veri yapısını import edin
import { getAllBanners, getAllGalleries, getAnnouncements, getNews } from '../utils/api'; // API fonksiyonlarını import edin


const Home = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [news, setNews] = useState([]); // Haberleri tutmak için state
    const [galleries, setGalleries] = useState([]); // Haberleri tutmak için state
    const [announcements, setAnnouncements] = useState([]); // Haberleri tutmak için state
    const [banners, setBanners] = useState([]); // Haberleri tutmak için state
    const [error, setError] = useState(null); // Hata durumunu göstermek için state

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };
    const fetchNews = async () => {
        try {
            const newsData = await getNews(); // Haber verilerini API'den çekiyoruz
            setNews(newsData);
        } catch (error) {
            setError('Haberler yüklenemedi.');
        }
    };
    const fetchAnnouncements = async () => {
        try {
            const announcementData = await getAnnouncements(); // Haber verilerini API'den çekiyoruz
            setAnnouncements(announcementData);
        } catch (error) {
            setError('Haberler yüklenemedi.');
        }
    };
    const fetchGalleries = async () => {
        try {
            const galleryData = await getAllGalleries(); // Haber verilerini API'den çekiyoruz
            setGalleries(galleryData);
        } catch (error) {
            setError('Haberler yüklenemedi.');
        }
    };
    const fetchBanners = async () => {
        try {
            const bannerData = await getAllBanners(); // Haber verilerini API'den çekiyoruz
            setBanners(bannerData);
        } catch (error) {
            setError('Banner yüklenemedi.');
        }
    };
    useEffect(() => {
        fetchNews(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz
        fetchAnnouncements(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz
        fetchGalleries(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz
        fetchBanners(); // useEffect içinde veri çekme fonksiyonunu çağırıyoruz

    }, []);
    return (
        <Container fluid className="home-page">
            {/* Güncel Durum Carousel */}
            <Row className="mb-5">
                <Col>
                    <ImageCarousel images={banners && banners.length > 0 ? banners : data.banners} />
                </Col>
            </Row>

            {/* Haberler (Kaydırmalı) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Haberler</h2>
                    <NewsCarousel className="haber" newsItems={news && news.length > 0 ? news : data.news} />
                </Col>
            </Row>

            {/* Galeri */}
            <Row className="mb-4 section-content">
                <h4>Fotograflar</h4>
                <ImageGallery galleries={galleries && galleries.length > 0 ? galleries : data.galleries} openModal={openModal} />
            </Row>

            {/* Videolar */}
            <Row className="mb-4 section-content">
                <h4>Videolar</h4>
                {/*<VideoEmbed videos={data.videos} openModal={openModal} />*/}
                <VideoGallery videos={data.videos} openModal={openModal} />
            </Row>


            {/* Duyurular (Başlık ve Detay) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Duyurular</h2>
                    <AnnouncementsList announcements={announcements && announcements.length > 0 ? announcements : data.announcements} />
                </Col>
            </Row>


            {/* Banner */}
            <Row className="mb-4 ">
                <Col>
                    <div className="banner" />
                </Col>
            </Row>


            {/* Modal */}
            <Modal isOpen={modalOpen} onClose={closeModal} content={modalContent} />
        </Container>
    );
};

export default Home;
