import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../css/ImageCarousel.css'; // Stil dosyasını ekleyin

const ImageCarousel = ({ images }) => {

    return (
        <Carousel className="small-carousel">
            {images.slice().reverse().map((image, index) => (
                <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image?.banner_pic} alt={`Slide ${index + 1}`} />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default ImageCarousel;

