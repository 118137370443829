import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import '../css/VideoGallery.css'; // CSS dosyasının yüklendiğinden emin olun

const VideoGallery = ({ videos }) => {
    const [modalContent, setModalContent] = useState(null);

    const openModal = (videoUrl) => {
        setModalContent(
            <div className="modal-overlay">
                <div className="modal-content">
                    {/* Sağ üst köşeye şık kapama butonu */}
                    <button className="modal-close" onClick={closeModal}></button>
                    <iframe
                        className="modal-iframe"
                        src={`https://www.youtube.com/embed/${videoUrl}`}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        );
    };

    const closeModal = () => {
        setModalContent(null);
    };

    return (
        <>
            {/* Carousel for video thumbnails */}
            <Carousel className="image-carousel">
                {videos.map((video, index) => (
                    <Carousel.Item key={index}>
                        <div className="carousel-content">
                            <div className="carousel-image-container video-container">
                                {/* Video Thumbnail */}
                                <img
                                    className="gallery-image video-thumbnail"
                                    src={`https://img.youtube.com/vi/${video.url.split('/').pop()}/hqdefault.jpg`} // YouTube thumbnail
                                    alt={`Slide ${index + 1}`}
                                    onClick={() => openModal(video.url.split('/').pop())}
                                />
                                <div className="play-icon">▶</div>
                            </div>

                            {/* Video Details */}
                            <div className="carousel-details">
                                <h2 className="carousel-title">{video.title}</h2>
                                <p className="carousel-description">{video.description}</p>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* Modal for playing videos */}
            {modalContent && (
                <div className="modal-overlay">
                    {modalContent}
                </div>
            )}
        </>
    );
};

export default VideoGallery;
