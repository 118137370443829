import React from 'react';
import { FaLock } from 'react-icons/fa'; // FontAwesome icon (you can use any icon package)
import '../css/Unauthorized.css'; // Custom styles if needed

const Unauthorized = () => {
    return (
        <div className="unauthorized-container d-flex flex-column align-items-center justify-content-center text-center">
            <FaLock size={80} className="mb-3 text-danger" />
            <h1>Yetkisiz Erişim</h1>
            <p>Bu sayfaya erişim yetkiniz yok.</p>
        </div>
    );
};

export default Unauthorized;
