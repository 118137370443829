import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../css/ImageCarouselGallery.css'; // Stil dosyası


const ImageCarouselGallery = ({ images }) => {
    return (
        <Carousel>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="modal-image"
                        src={image.gallery_pic}
                        alt={`Carousel Image ${index + 1}`}
                        style={{ objectFit: 'contain' }} // Inline style to ensure the image fits well
                    />
                </Carousel.Item>
            ))}
        </Carousel>

    );
};

export default ImageCarouselGallery;
