import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import misyon from '../assets/misyon.png';
import vizyon from '../assets/vizyon.png';
import data from '../assets/data/Media'
import { getStaticTextById } from '../utils/api'; // API fonksiyonunu import edin

const About = () => {
  const [missionText, setMissionText] = useState(data.aboutText.missonText);
  const [visionText, setVisionText] = useState(data.aboutText.visionText);


  useEffect(() => {
    const fetchStaticText = async () => {
      try {
        // API'den misyon ve vizyon metinlerini çekiyoruz
        const missionData = await getStaticTextById(2); // Misyon metni için ID 1
        const visionData = await getStaticTextById(3); // Vizyon metni için ID 2
        setMissionText(missionData.content ? missionData.content : data.aboutText.missonText);
        setVisionText(visionData.content ? visionData.content : data.aboutText.visionText);
      } catch (error) {
        console.error('Metinler çekilemedi:', error.message);
      }
    };

    fetchStaticText();
  }, []);


  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1>Hakkımızda</h1>

          {/* Misyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={misyon}
              alt="Misyon"
              style={{
                width: '80%',
                maxWidth: '250px',
                minWidth: '150px',
                borderRadius: '8px'
              }}
            />
            <p>{missionText}</p> {/* Dinamik misyon metni */}
          </div>

          {/* Vizyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={vizyon}
              alt="Vizyon"
              style={{
                width: '100%',
                maxWidth: '250px',
                minWidth: '150px',
                borderRadius: '8px'
              }}
            />
            <p>{visionText}</p> {/* Dinamik vizyon metni */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
