import React, { useState } from 'react';
import '../css/AdminPanel.css'; // Stil dosyanızı buraya ekleyin
import { Tab, Tabs } from 'react-bootstrap';
import UserList from '../pages/UserList';
import AnnouncementList from './AnnouncementList';
import NewsList from './NewsList';
import BranchesList from './BranchesList';
import GalleryList from './GalleryList';
import BannerImages from './BannerImages';
import AboutEdit from './AboutEdit';
import ContactEdit from './ContactEdit';
import MessageOfPresidentEdit from './MessageOfPresidentEdit';

function AdminPanel() {
    const [activeTab, setActiveTab] = useState('about');

    return (
        <div className="admin-panel">
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
                <Tab eventKey="about" title="Hakkımızda">
                    <AboutEdit active={activeTab === 'about'} />
                </Tab>
                <Tab eventKey="branches" title="Şubelerimiz">
                    <BranchesList active={activeTab === 'branches'} />
                </Tab>
                <Tab eventKey="contact" title="İletişim">
                    <ContactEdit active={activeTab === 'contact'} />
                </Tab>
                <Tab eventKey="news" title="Haberler">
                    <NewsList active={activeTab === 'news'} />
                </Tab>
                <Tab eventKey="announcements" title="Duyurular">
                    <AnnouncementList active={activeTab === 'announcements'} />
                </Tab>
                <Tab eventKey="message" title="Başkanın Mesajı">
                    <MessageOfPresidentEdit active={activeTab === 'message'} />
                </Tab>
                <Tab eventKey="gallery" title="Galeri">
                    <GalleryList active={activeTab === 'gallery'} />
                </Tab>
                <Tab eventKey="banner" title="Banner">
                    <BannerImages active={activeTab === 'banner'} />
                </Tab>
                <Tab eventKey="users" title="Üyeler">
                    <UserList active={activeTab === 'users'} />
                </Tab>
            </Tabs>
        </div>
    );
}

export default AdminPanel;
