import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you import Bootstrap CSS
import '../css/SignUp.css'; // Assuming the CSS styles are in SignUp.css
import { signup } from '../utils/api'; // API'den signup fonksiyonunu içe aktar
const SignUp = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            await signup(username, email, password); // API'den kayıt işlemini gerçekleştir
            setSuccess('Kullanıcı başarıyla kaydedildi!');
            setUsername('');
            setPassword('');
            navigate('/');
        } catch (err) {
            setError('Kayıt hatası: ' + err.message);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center">Üyelik Oluştur</h2>
                            <form onSubmit={handleSignUp}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Kullanıcı Adı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Adınızı girin"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">E-mail</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="E-mail girin"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Şifre</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Şifre girin"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="creative-signup-btn w-100">Kaydol</button>
                            </form>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            {success && <p style={{ color: 'green' }}>{success}</p>}
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <small>
                            Zaten üye misin? <a href="#/signin">Buradan Giriş Yap</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;