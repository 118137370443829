import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Rights.css';
import data from '../assets/data/Media'; // Haklar verisini JSON dosyasından alıyoruz

const Rights = () => {
    return (
        <div className="haklar-container">
            <div className="haklar-header text-center">
                <h2>Şehit Yakınları ve Gazi Hakları</h2>
                <p className="haklar-description"></p>
            </div>

            <div className="container">
                <ol className="haklar-list">
                    {data.rightsData.map((right) => (
                        <li key={right.id} className="hak-item">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h5 className="card-title">{right.title}</h5>
                                    <Link to={`/rights/${right.id}`} className="btn btn-primary">Detayları Gör</Link> {/* Dinamik link */}
                                </div>
                            </div>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default Rights;
