import React, { useEffect, useState } from 'react';
import { getAnnouncements, createAnnouncement, updateAnnouncement, deleteAnnouncement } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';  // Import Toastify
import '../css/AnnouncementList.css'; // Import the CSS file

const AnnouncementList = ({ active }) => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newAnnouncement, setNewAnnouncement] = useState({
        title: '', detail: '', date: new Date().toISOString().split('T')[0] // Set default to today's date
    });
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const [isLoadingAction, setIsLoadingAction] = useState(false); // State for loading actions

    const fetchAnnouncements = async () => {
        setLoading(true);
        try {
            const data = await getAnnouncements();
            setAnnouncements(data);
        } catch (error) {
            toast.error(`Duyurular yüklenirken hata: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (active) {
            fetchAnnouncements();
        }
        if (!active) {
            setEditingAnnouncement(null);
            setIsEditingEnabled(false);
            setIsEnabled(false);
        }
    }, [active]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAnnouncement({ ...newAnnouncement, [name]: value });
    };
    const isValid = (newAnnouncement) => {
        const requiredFields = ['title', 'detail', 'date'];
        for (let field of requiredFields) {
            if (!newAnnouncement[field]) {
                toast.error('Tüm alanların doldurulması gerekmektedir!');
                return false;
            }
        }
        return true;
    };

    const handleAddAnnouncement = async (e) => {
        e.preventDefault();

        if (!isValid(newAnnouncement)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedAnnouncement = await createAnnouncement(newAnnouncement);
            setAnnouncements([addedAnnouncement, ...announcements]);
            setNewAnnouncement({ title: '', detail: '', date: new Date().toISOString().split('T')[0] });
            toast.success('Duyuru başarıyla eklendi!');
        } catch (error) {
            toast.error(`Duyuru eklenirken hata: ${error.message}`);
        } finally {
            setIsLoadingAction(false);
        }
    };

    const handleEditAnnouncement = (announcement) => {
        setEditingAnnouncement({
            ...announcement,
            date: new Date(announcement.date).toISOString().substr(0, 10) // Datepicker için format
        });
        setIsEditingEnabled(true);
    };

    const handleUpdateAnnouncement = async (e) => {
        e.preventDefault();
        if (!isValid(editingAnnouncement)) { // Eğer form geçersizse API çağrısı yapılmaz
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            await updateAnnouncement(editingAnnouncement.id, editingAnnouncement);
            setAnnouncements(announcements.map((announcement) => (announcement.id === editingAnnouncement.id ? editingAnnouncement : announcement)));
            setEditingAnnouncement(null);
            setIsEditingEnabled(false);
            toast.success('Duyuru başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Duyuru güncellenirken hata: ${error.message}`);
        } finally {
            setIsLoadingAction(false);
        }
    };
    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = (id, closeToast) => {
        // Silme işlemi burada gerçekleştirilecek
        try {
            deleteAnnouncement(id);
            setAnnouncements(announcements.filter(announcement => announcement.id !== id));
            toast.success('Kullanıcı başarıyla silindi!');
        } catch (error) {
            toast.error(`Kullanıcı silinirken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingAnnouncement({ ...editingAnnouncement, [name]: value });
    };

    const handleCancelEdit = () => {
        setEditingAnnouncement(null); // Düzenleme modunu kapatır
        setIsEditingEnabled(false); // Düzenlemeyi devre dışı bırakır
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Yükleniyor...
            </div>
        );
    }

    return (
        <div className="announcement-list-container mb-5">
            <table className="announcement-list-table">
                <thead>
                    <tr>
                        <th>Başlık</th>
                        <th>İçerik</th>
                        <th>Tarih</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="title"
                                placeholder="Duyuru Başlığı"
                                value={newAnnouncement.title || ""}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="detail"
                                placeholder="Duyuru İçeriği"
                                value={newAnnouncement.detail || ""}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="date"
                                name="date"
                                value={newAnnouncement.date || ""}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddAnnouncement} className="btn-announcement" title="Duyuru Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => setIsEnabled(!isEnabled)} className="btn-announcement" title="Ekleme Modunu Aç/Kapa">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {announcements.map((announcement) => (
                        <tr key={announcement.id}>
                            {editingAnnouncement?.id === announcement.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingAnnouncement.title || ""}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="detail"
                                            value={editingAnnouncement.detail || ""}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingAnnouncement.date || ""}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleUpdateAnnouncement} className="btn-announcement" title="Güncelle" disabled={isLoadingAction}>
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-user" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{announcement.title || ""}</td>
                                    <td>{announcement.detail || ""}</td>
                                    <td>{new Date(announcement.date || "").toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleEditAnnouncement(announcement)} className="btn-announcement" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(announcement.id)} className="btn-announcement" title="Sil" disabled={isLoadingAction}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer position='bottom-right' />
        </div>
    );
};

export default AnnouncementList;
